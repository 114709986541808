import React from "react"
import "./styles.css"


function Card({children}) {
  return (
    <div className="card">
      {children}
    </div>
  )
}

export default Card